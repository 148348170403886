import React, { useState } from "react";
import { Typography, Grid, Container } from "@mui/material";
import { Navigate, useNavigate } from "react-router-dom";
import { useAuth } from "../../components/hooks/useAuth";
import TextInput from "../../components/TextInput";
import InputLablessss from "../../components/TextLabel";
import CustomButton from "../../components/CustomButton";
import SnackbarAlert from "../../components/SnackbarAlert";
import Loader from "../../components/Loader";
import AuthBackground from "../../components/AuthBackground";
import { allColors } from "../../constants/Color";
import { loginAdmin } from "../../services/adminAuthentication";

const Login = () => {
  const navigate = useNavigate();
  const { login, user } = useAuth();
  const [isloading, setIsLoading] = useState(false);
  const [inputValues, setInputValues] = useState({
    email: "",
    password: "",
  });

  const [snackbarProps, setSnackbarProps] = useState({
    open: false,
    type: "",
    message: "",
  });

  const handleSnackbarVal = (open, type, message) => {
    setSnackbarProps({
      open,
      type,
      message,
    });
  };

  const handleCloseSnackbar = () => {
    handleSnackbarVal(false, "", "");
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setInputValues({
      ...inputValues,
      [name]: value,
    });
  };

  const handleLogin = () => {
    if (inputValues.email == "") {
      handleSnackbarVal(true, "error", "Username is required");
    } else if (inputValues.password == "") {
      handleSnackbarVal(true, "error", "Password is required");
    } else {
      let payload = {
        email: inputValues.email,
        password: inputValues.password,
      };
      setIsLoading(true);
      loginAdmin(payload)
        .then((response) => {
          console.log(response.data.data, "response.data.data");
          console.log(response, "Sdasdasdasdasd");
          setIsLoading(false);
          if (response?.data?.status == "ok") {
            localStorage.setItem("token", response.data.data.token);
            localStorage.setItem(
              "user",
              JSON.stringify(response.data.data.userDetails)
            );
            setInputValues({
              email: "",
              password: "",
            });
            login(response.data.data.userDetails);
            window.location.pathname = "/";
          } else {
            handleSnackbarVal(true, "error", response?.data?.message);
          }
        })
        .catch((error) => {
          setIsLoading(false);
          handleSnackbarVal(true, "error", error?.message);
        });
    }
  };

  if (user) {
    return <Navigate to="/" replace />;
  }

  return (
    <AuthBackground>
      <SnackbarAlert
        snackbarProps={snackbarProps}
        handleCloseSnackbar={handleCloseSnackbar}
      />
      <Loader isloading={isloading} />

      <Container
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          borderRadius: "10px",
          maxWidth: "390px !important",
        }}
      >
        <Typography
          variant={"h2"}
          fontSize={30}
          fontWeight={700}
          marginRight={"auto"}
        >
          Login
        </Typography>
        <Typography variant={"body1"} fontSize={14} marginRight={"auto"}>
          Welcome back! Please login to your account.
        </Typography>
        <Grid container mt={3}>
          <Grid item xs={12} md={12}>
            <InputLablessss label={"Email"} />
            <TextInput
              type={"text"}
              placeholder={"Email"}
              name={"email"}
              value={inputValues.email}
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <InputLablessss label={"Password"} />
            <TextInput
              placeholder={"Password"}
              type={"password"}
              name={"password"}
              value={inputValues.password}
              onChange={handleInputChange}
            />
          </Grid>
        </Grid>
        <Typography
          variant={"body2"}
          fontSize={12}
          color={allColors.red}
          margin={"10px 0px 10px auto"}
          sx={{ cursor: "pointer", textDecoration: "underline" }}
          onClick={() => navigate("/forgotPassword")}
        >
          Forgot Password
        </Typography>
        <CustomButton
          btnLabel={"Login"}
          height={45}
          handlePressBtn={handleLogin}
        />
      </Container>
    </AuthBackground>
  );
};

export default Login;
