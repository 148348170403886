import Api from "./index";
import { endPoints, requestType } from "../constants/variables";

export const fetchCongratulation = () => {
  return Api(endPoints.getCongratulation, null, requestType.GET);
};

export const updateCongratulation = (id, params) => {
  return Api(
    `${endPoints.updateCongratulation}/${id}`,
    params,
    requestType.PUT
  );
};
