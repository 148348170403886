import Api from "./index";
import { endPoints, requestType } from "../constants/variables";

export const fetchSetting = () => {
  return Api(endPoints.getSetting, null, requestType.GET);
};

export const updateSetting = (id, params) => {
  return Api(`${endPoints.updateSetting}/${id}`, params, requestType.PUT);
};
