import Api from "./index";
import { endPoints, requestType } from "../constants/variables";

export const fetchLoader = () => {
  return Api(endPoints.getLoader, null, requestType.GET);
};

export const updateLoader = (id, params) => {
  return Api(`${endPoints.updateLoader}/${id}`, params, requestType.PUT);
};
