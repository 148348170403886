import { makeStyles } from "@mui/styles";
import { primaryColor } from "../../../constants/Color";

const SettingStyle = makeStyles((theme) => ({
  portfolioCard: {
    padding: "20px",
    boxShadow: "rgba(0, 0, 0, 0.18) 0px 2px 4px",
    borderRadius: "10px",
    borderBottom: `4px solid ${primaryColor}`,
  },
  updateBtn: {
    "&.MuiButton-root": {
      backgroundColor: primaryColor,
      textTransform: "capitalize",
      width: "100% !important",
      "&:hover": {
        backgroundColor: "#000",
      },
    },
  },
}));

export default SettingStyle;
