import Api from "./index";
import { endPoints, requestType } from "../constants/variables";

export const fetchWinner = () => {
  return Api(endPoints.getWinner, null, requestType.GET);
};

export const updateWinner = (id, params) => {
  return Api(`${endPoints.updateWinner}/${id}`, params, requestType.PUT);
};
