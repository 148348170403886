import Api from "./index";
import { endPoints, requestType } from "../constants/variables";

export const createHelp = (params) => {
  return Api(endPoints.createHelp, params, requestType.POST);
};

export const getAllHep = () => {
  return Api(endPoints.getAllHelp, null, requestType.GET);
};

export const updateHelpById = (id, params) => {
  return Api(`${endPoints.updateHelp}/${id}`, params, requestType.PUT);
};

export const deleteHelp = (id) => {
  return Api(`${endPoints.deletHelp}/${id}`, null, requestType.DELETE);
};
