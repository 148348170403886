import Api from "./index";
import { endPoints, requestType } from "../constants/variables";

export const getPromo = () => {
  return Api(endPoints.getPromo, null, requestType.GET);
};

export const updatePromo = (id, params) => {
  return Api(`${endPoints.updatePromo}/${id}`, params, requestType.PUT);
};
