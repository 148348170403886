import React, { useState } from "react";
import { Box, Grid, Typography } from "@mui/material";
import DrawerComponent from "../../../components/NavigationDrawer";
import InputLablessss from "../../../components/TextLabel";
import TextInput from "../../../components/TextInput";
import CustomButton from "../../../components/CustomButton";
import SnackbarAlert from "../../../components/SnackbarAlert";
import Loader from "../../../components/Loader";
import { useEffect } from "react";
import { fetchCongratulation, updateCongratulation } from "../../../services/contratulationApi";

function Congratulation() {
  const [isLoading, setIsLoading] = useState(false);
  const [congratulationData, setCongratulationData] = useState(null);
  const [inputValues, setInputValues] = useState({
    heading: "",
    title: "",
  });
  const [snackbarProps, setSnackbarProps] = useState({
    open: false,
    type: "",
    message: "",
  });

  const handleSnackbarVal = (open, type, message) => {
    setSnackbarProps({
      open,
      type,
      message,
    });
  };

  const handleCloseSnackbar = () => {
    handleSnackbarVal(false, "", "");
  };

  useEffect(() => {
    getCongartulationData();
  }, []);

  const getCongartulationData = () => {
    setIsLoading(true);
    fetchCongratulation()
      .then((res) => {
        setIsLoading(false);
        if (res?.data?.status == "ok") {
          let data = res?.data?.data;
          setInputValues({
            heading: data?.heading,
            title: data?.title,
          });
          setCongratulationData(data);
          console.log(data, "datadatadata");
        } else {
          handleSnackbarVal(true, "error", res?.data?.message);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        handleSnackbarVal(true, "error", err?.message);
      });
  };

  const handleChangeInput = (e) => {
    const { value, name } = e.target;
    setInputValues({
      ...inputValues,
      [name]: value,
    });
  };

  const handleUpdate = () => {
    const { heading, title } = inputValues;
    if (!heading) {
      handleSnackbarVal(true, "error", "Heading  is required");
    } else if (!title) {
      handleSnackbarVal(true, "error", "Title is required");
    } else {
      setIsLoading(true);
      let payload = {
        heading,
        title,
      };
      updateCongratulation(congratulationData?._id, payload)
        .then((res) => {
          setIsLoading(false);
          if (res?.data?.status == "ok") {
            handleSnackbarVal(true, "success", res?.data?.message);
            getCongartulationData();
          } else {
            handleSnackbarVal(true, "error", res?.data?.message);
          }
        })
        .catch((err) => {
          setIsLoading(false);
          handleSnackbarVal(true, "error", err?.message);
        });
    }
  };
  return (
    <DrawerComponent>
      <SnackbarAlert
        snackbarProps={snackbarProps}
        handleCloseSnackbar={handleCloseSnackbar}
      />
      <Loader isloading={isLoading} />
      <Typography variant={"h6"} fontSize={22} fontWeight={600}>
        Congratulation Page
      </Typography>
      <Grid container spacing={2} mt={2} alignItems={"end"}>
        <Grid item xs={12} md={4}>
          <InputLablessss label={"Heading"} />
          <TextInput
            type={"text"}
            name={"heading"}
            value={inputValues.heading}
            onChange={handleChangeInput}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <InputLablessss label={"Title"} />
          <TextInput
            type={"text"}
            name={"title"}
            value={inputValues.title}
            onChange={handleChangeInput}
          />
        </Grid>
        <Grid item xs={12} md={2}>
          <Box width={"100%"} height={40}>
            <CustomButton btnLabel={"Update"} handlePressBtn={handleUpdate} />
          </Box>
        </Grid>
      </Grid>
    </DrawerComponent>
  );
}

export default Congratulation;
