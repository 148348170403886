import Api from "./index";
import { endPoints, requestType } from "../constants/variables";

export const fetchPackages = () => {
  return Api(endPoints.getPackages, null, requestType.GET);
};

export const updatePackages = (id, params) => {
  return Api(`${endPoints.updatePackages}/${id}`, params, requestType.PUT);
};

export const fetchPackagesHeading = () => {
  return Api(endPoints.getPlansHeading, null, requestType.GET);
};

export const updatePackagesHeading = (id, params) => {
  return Api(`${endPoints.updatePlansHeading}/${id}`, params, requestType.PUT);
};
