import React, { useEffect, useState } from "react";
import DrawerComponent from "./../../../components/NavigationDrawer";
import {
  Box,
  FormControlLabel,
  Grid,
  Paper,
  Switch,
  Typography,
} from "@mui/material";
import Loader from "../../../components/Loader";
import SnackbarAlert from "../../../components/SnackbarAlert";
import {
  fetchPackages,
  fetchPackagesHeading,
  updatePackages,
  updatePackagesHeading,
} from "../../../services/packages";
import DynamicTable from "./../../../components/dynamicTable/index";
import PackageEditDialog from "./PackageEditDialog";
import InputLablessss from "../../../components/TextLabel";
import TextInput from "../../../components/TextInput";
import CustomButton from "../../../components/CustomButton";
import { getPromo, updatePromo } from "./../../../services/promo";

const headerData = [
  {
    id: "packageName",
    numeric: false,
    disablePadding: true,
    label: "Package Name",
  },
  {
    id: "comments",
    numeric: false,
    disablePadding: true,
    label: "Comments",
  },
  {
    id: "price",
    numeric: false,
    disablePadding: true,
    label: "Price",
  },
  {
    id: "duplicate",
    numeric: false,
    disablePadding: true,
    label: "Duplicate",
  },
  {
    id: "hastag",
    numeric: false,
    disablePadding: true,
    label: "Hashtag",
  },
  {
    id: "extraChance",
    numeric: false,
    disablePadding: true,
    label: "Extra Chance",
  },
  {
    id: "block",
    numeric: false,
    disablePadding: true,
    label: "Block",
  },
  {
    id: "branding",
    numeric: false,
    disablePadding: true,
    label: "Branding",
  },
  {
    id: "countDown",
    numeric: false,
    disablePadding: true,
    label: "Count Down",
  },
  {
    id: "multiNetwork",
    numeric: false,
    disablePadding: true,
    label: "Multi Network",
  },
  {
    id: "actions",
    numeric: true,
    disablePadding: false,
    label: "Actions",
  },
];

const diplayRows = [
  "type",
  "comments",
  "price",
  "showDuplicate",
  "showHashtag",
  "showExtraChance",
  "showBlockList",
  "showBranding",
  "showCountDown",
  "showMultiNetwork",
  "actions",
];

function PackagesPlan() {
  const [isLoading, setIsLoading] = useState(false);
  const [rowsData, setRowsData] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [dialogData, setDialogData] = useState(null);
  const [packageData, setPackageData] = useState(null);
  const [promoData, setPromoData] = useState(null);
  const [promoValues, setPromoValues] = useState({
    promoText: "",
    promoDiscount: "",
    promoCode: "",
    isAvailable: false,
  });

  const [inputValues, setInputValues] = useState({
    heading1: "",
    heading2: "",
    heading3: "",
  });

  const [inputVal, setInputVal] = useState({
    type: "",
    comments: "",
    price: "",
  });

  const [snackbarProps, setSnackbarProps] = useState({
    open: false,
    type: "",
    message: "",
  });

  const handleSnackbarVal = (open, type, message) => {
    setSnackbarProps({
      open,
      type,
      message,
    });
  };

  const handleCloseSnackbar = () => {
    handleSnackbarVal(false, "", "");
  };

  useEffect(() => {
    getPackages();
    getPackagesHeading();
    fetchPromo();
  }, []);

  const getPackages = () => {
    setIsLoading(true);
    fetchPackages()
      .then((res) => {
        setIsLoading(false);
        if (res?.data?.status == "ok") {
          let data = res?.data?.data;
          setRowsData(data);
          console.log(data, "getPackagesgetPackages");
        } else {
          handleSnackbarVal(true, "error", res?.data?.message);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        handleSnackbarVal(true, "error", err?.message);
      });
  };

  const fetchPromo = () => {
    setIsLoading(true);
    getPromo()
      .then((res) => {
        setIsLoading(false);
        if (res?.data?.status == "ok") {
          let data = res?.data?.data;

          console.log(data, "asjhajhasdjhjhasasjhhasd");
          setPromoData(data);
          setPromoValues({
            promoText: data?.promoText,
            promoDiscount: data.promoDiscount,
            isAvailable: data?.isAvailable,
            promoCode: data?.promoCode,
          });
        } else {
          handleSnackbarVal(true, "error", res?.data?.message);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        handleSnackbarVal(true, "error", "Somthing went wrong");
      });
  };

  const getPackagesHeading = () => {
    setIsLoading(true);
    fetchPackagesHeading()
      .then((res) => {
        setIsLoading(false);
        if (res?.data?.status == "ok") {
          let data = res?.data?.data;
          setInputValues({
            heading1: data?.heading1,
            heading2: data?.heading2,
            heading3: data?.heading3,
          });
          setPackageData(data);
        } else {
          handleSnackbarVal(true, "error", res?.data?.message);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        handleSnackbarVal(true, "error", err?.message);
      });
  };

  const handleChangeDuplicate = (e, row) => {
    let payload = {
      duplicate: e,
    };
    setIsLoading(true);
    updatePackages(row._id, payload)
      .then((res) => {
        setIsLoading(false);
        if (res?.data?.status == "ok") {
          handleSnackbarVal(true, "success", res?.data?.message);
          getPackages();
        } else {
          handleSnackbarVal(true, "error", res?.data?.message);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        handleSnackbarVal(true, "error", err?.message);
      });
  };

  const handleChangeHashtag = (e, row) => {
    let payload = {
      hashtag: e,
    };
    setIsLoading(true);
    updatePackages(row._id, payload)
      .then((res) => {
        setIsLoading(false);
        if (res?.data?.status == "ok") {
          handleSnackbarVal(true, "success", res?.data?.message);
          getPackages();
        } else {
          handleSnackbarVal(true, "error", res?.data?.message);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        handleSnackbarVal(true, "error", err?.message);
      });
  };

  const handleChangeBlock = (e, row) => {
    let payload = {
      blockList: e,
    };
    setIsLoading(true);
    updatePackages(row._id, payload)
      .then((res) => {
        setIsLoading(false);
        if (res?.data?.status == "ok") {
          handleSnackbarVal(true, "success", res?.data?.message);
          getPackages();
        } else {
          handleSnackbarVal(true, "error", res?.data?.message);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        handleSnackbarVal(true, "error", err?.message);
      });
  };

  const handleChangeChances = (e, row) => {
    let payload = {
      extraChance: e,
    };
    setIsLoading(true);
    updatePackages(row._id, payload)
      .then((res) => {
        setIsLoading(false);
        if (res?.data?.status == "ok") {
          handleSnackbarVal(true, "success", res?.data?.message);
          getPackages();
        } else {
          handleSnackbarVal(true, "error", res?.data?.message);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        handleSnackbarVal(true, "error", err?.message);
      });
  };

  const handleChangeBranding = (e, row) => {
    let payload = {
      branding: e,
    };
    setIsLoading(true);
    updatePackages(row._id, payload)
      .then((res) => {
        setIsLoading(false);
        if (res?.data?.status == "ok") {
          handleSnackbarVal(true, "success", res?.data?.message);
          getPackages();
        } else {
          handleSnackbarVal(true, "error", res?.data?.message);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        handleSnackbarVal(true, "error", err?.message);
      });
  };

  const handleChangeCountDown = (e, row) => {
    let payload = {
      countDown: e,
    };
    setIsLoading(true);
    updatePackages(row._id, payload)
      .then((res) => {
        setIsLoading(false);
        if (res?.data?.status == "ok") {
          handleSnackbarVal(true, "success", res?.data?.message);
          getPackages();
        } else {
          handleSnackbarVal(true, "error", res?.data?.message);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        handleSnackbarVal(true, "error", err?.message);
      });
  };

  const handleChangeMultiNetwork = (e, row) => {
    let payload = {
      multiNetwork: e,
    };
    setIsLoading(true);
    updatePackages(row._id, payload)
      .then((res) => {
        setIsLoading(false);
        if (res?.data?.status == "ok") {
          handleSnackbarVal(true, "success", res?.data?.message);
          getPackages();
        } else {
          handleSnackbarVal(true, "error", res?.data?.message);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        handleSnackbarVal(true, "error", err?.message);
      });
  };

  const handleOpenDialog = (row) => {
    setOpenDialog(true);
    setDialogData(row);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setDialogData(null);
  };

  const handleChangeInput = (e) => {
    const { value, name } = e.target;
    setInputValues({
      ...inputValues,
      [name]: value,
    });
  };

  const handleChangePromo = (e) => {
    const { value, name, checked } = e.target;

    setPromoValues((prevValues) => ({
      ...prevValues,
      [name]: name === "isAvailable" ? checked : value,
    }));
  };

  const handleUpdatePackagesHeading = () => {
    const { heading1, heading2, heading3 } = inputValues;
    if (!heading1) {
      handleSnackbarVal(true, "error", "Heading one is required");
    } else if (!heading2) {
      handleSnackbarVal(true, "error", "Heading two is required");
    } else if (!heading3) {
      handleSnackbarVal(true, "error", "Heading three is required");
    } else {
      setIsLoading(true);
      let payload = {
        heading1,
        heading2,
        heading3,
      };
      updatePackagesHeading(packageData?._id, payload)
        .then((res) => {
          setIsLoading(false);
          if (res?.data?.status == "ok") {
            handleSnackbarVal(true, "success", res?.data?.message);
            getPackagesHeading();
          } else {
            handleSnackbarVal(true, "error", res?.data?.message);
          }
        })
        .catch((err) => {
          setIsLoading(false);
          handleSnackbarVal(true, "error", err?.message);
        });
    }
  };

  const handleUpdatePromo = () => {
    let payload = {
      promoText: promoValues?.promoText,
      promoDiscount: promoValues?.promoDiscount,
      isAvailable: promoValues?.isAvailable,
      promoCode: promoValues?.promoCode,
    };
    setIsLoading(true);
    updatePromo(promoData?._id, payload)
      .then((response) => {
        setIsLoading(false);
        if (response?.data?.status === "ok") {
          fetchPromo();
          handleSnackbarVal(true, "success", response?.data?.message);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        handleSnackbarVal(true, "error", "Somthing went wrong");
      });
  };

  return (
    <DrawerComponent>
      <Loader isloading={isLoading} />
      <SnackbarAlert
        snackbarProps={snackbarProps}
        handleCloseSnackbar={handleCloseSnackbar}
      />
      <Typography variant={"h6"} fontSize={20} fontWeight={600}>
        Update Promo
      </Typography>

      <Paper elevation={2} sx={{ padding: 2, mt: 2, mb: 2 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <InputLablessss label={"Promo Text"} />
            <TextInput
              type={"text"}
              name={"promoText"}
              value={promoValues.promoText}
              onChange={handleChangePromo}
            />
          </Grid>
          <Grid item xs={12} md={2}>
            <InputLablessss label={"Promo Discount"} />
            <TextInput
              type={"number"}
              name={"promoDiscount"}
              value={promoValues.promoDiscount}
              onChange={handleChangePromo}
            />
          </Grid>
          <Grid item xs={12} md={2}>
            <InputLablessss label={"Promo code"} />
            <TextInput
              type={"text"}
              name={"promoCode"}
              value={promoValues.promoCode}
              onChange={handleChangePromo}
            />
          </Grid>
          <Grid item xs={12} md={2}>
            <InputLablessss label={"Available"} />
            <FormControlLabel
              control={
                <Switch
                  size="large"
                  name="isAvailable"
                  checked={promoValues?.isAvailable}
                  onChange={handleChangePromo}
                />
              }
            />
          </Grid>
        </Grid>

        <Box width={150} height={40} margin={"10px 0px 10px auto"}>
          <CustomButton
            btnLabel={"Update"}
            handlePressBtn={handleUpdatePromo}
          />
        </Box>
      </Paper>

      <Typography variant={"h6"} fontSize={20} fontWeight={600}>
        Update Packages Heading
      </Typography>

      <Paper elevation={2} sx={{ padding: 2, mt: 2 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={4}>
            <InputLablessss label={"Heading 1"} />
            <TextInput
              type={"text"}
              name={"heading1"}
              value={inputValues.heading1}
              onChange={handleChangeInput}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <InputLablessss label={"Heading 2"} />
            <TextInput
              type={"text"}
              name={"heading2"}
              value={inputValues.heading2}
              onChange={handleChangeInput}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <InputLablessss label={"Heading 3"} />
            <TextInput
              type={"text"}
              name={"heading3"}
              value={inputValues.heading3}
              onChange={handleChangeInput}
            />
          </Grid>
        </Grid>

        <Box width={150} height={40} margin={"10px 0px 10px auto"}>
          <CustomButton
            btnLabel={"Update"}
            handlePressBtn={handleUpdatePackagesHeading}
          />
        </Box>
      </Paper>

      <Typography variant={"h6"} fontSize={20} fontWeight={600} mb={2} mt={3}>
        Update Packages
      </Typography>
      <DynamicTable
        headerData={headerData}
        bodyRows={rowsData}
        displayData={diplayRows}
        showEdit={true}
        openEditDialog={handleOpenDialog}
        handleChangeDuplicate={handleChangeDuplicate}
        handleChangeHashtag={handleChangeHashtag}
        handleChangeBlock={handleChangeBlock}
        handleChangeChances={handleChangeChances}
        handleChangeBranding={handleChangeBranding}
        handleChangeCountDown={handleChangeCountDown}
        handleChangeMultiNetwork={handleChangeMultiNetwork}
      />
      <PackageEditDialog
        open={openDialog}
        dialogData={dialogData}
        inputValue={inputVal}
        setInputValue={setInputVal}
        handleClose={handleCloseDialog}
        setIsLoading={setIsLoading}
        handleSnackbarVal={handleSnackbarVal}
        getPackages={getPackages}
      />
    </DrawerComponent>
  );
}

export default PackagesPlan;
