import React, { useEffect, useState } from "react";
import DrawerComponent from "../../../components/NavigationDrawer";
import { Box, Grid, Typography } from "@mui/material";
import InputLablessss from "../../../components/TextLabel";
import CustomButton from "../../../components/CustomButton";
import TextInput from "../../../components/TextInput";
import SnackbarAlert from "../../../components/SnackbarAlert";
import Loader from "../../../components/Loader";
import { fetchWinner, updateWinner } from "../../../services/winnerApi";

function Winner() {
  const [isLoading, setIsLoading] = useState(false);
  const [winnerData, setWinnerData] = useState(null);
  const [inputValues, setInputValues] = useState({
    boxHeading1: "",
    boxHeading2: "",
    boxHeading3: "",
  });
  const [snackbarProps, setSnackbarProps] = useState({
    open: false,
    type: "",
    message: "",
  });

  const handleSnackbarVal = (open, type, message) => {
    setSnackbarProps({
      open,
      type,
      message,
    });
  };

  const handleCloseSnackbar = () => {
    handleSnackbarVal(false, "", "");
  };

  useEffect(() => {
    getWinnergData();
  }, []);

  const getWinnergData = () => {
    setIsLoading(true);
    fetchWinner()
      .then((res) => {
        setIsLoading(false);
        if (res?.data?.status == "ok") {
          let data = res?.data?.data;
          setInputValues({
            boxHeading1: data?.boxHeading1,
            boxHeading2: data?.boxHeading2,
            boxHeading3: data?.boxHeading3,
          });
          setWinnerData(data);
          console.log(data, "datadatadata");
        } else {
          handleSnackbarVal(true, "error", res?.data?.message);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        handleSnackbarVal(true, "error", err?.message);
      });
  };

  const handleChangeInput = (e) => {
    const { value, name } = e.target;
    setInputValues({
      ...inputValues,
      [name]: value,
    });
  };

  const handleUpdate = () => {
    const { boxHeading1, boxHeading2, boxHeading3 } = inputValues;
    if (!boxHeading1) {
      handleSnackbarVal(true, "error", "Box Heading one is required");
    } else if (!boxHeading2) {
      handleSnackbarVal(true, "error", "Box Heading two is required");
    } else if (!boxHeading3) {
      handleSnackbarVal(true, "error", "Box Heading three is required");
    } else {
      setIsLoading(true);
      let payload = {
        boxHeading1,
        boxHeading2,
        boxHeading3,
      };
      updateWinner(winnerData?._id, payload)
        .then((res) => {
          setIsLoading(false);
          if (res?.data?.status == "ok") {
            handleSnackbarVal(true, "success", res?.data?.message);
            getWinnergData();
          } else {
            handleSnackbarVal(true, "error", res?.data?.message);
          }
        })
        .catch((err) => {
          setIsLoading(false);
          handleSnackbarVal(true, "error", err?.message);
        });
    }
  };
  return (
    <DrawerComponent>
      <SnackbarAlert
        snackbarProps={snackbarProps}
        handleCloseSnackbar={handleCloseSnackbar}
      />
      <Loader isloading={isLoading} />
      <Typography variant={"h6"} fontSize={22} fontWeight={600}>
        Winner Page
      </Typography>
      <Grid container spacing={2} alignItems={"end"}>
        <Grid item xs={12} md={4}>
          <InputLablessss label={"Box Heading 1"} />
          <TextInput
            type={"text"}
            name={"boxHeading1"}
            value={inputValues.boxHeading1}
            onChange={handleChangeInput}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <InputLablessss label={"Box Heading 2"} />
          <TextInput
            type={"text"}
            name={"boxHeading2"}
            value={inputValues.boxHeading2}
            onChange={handleChangeInput}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <InputLablessss label={"Box Heading 3"} />
          <TextInput
            type={"text"}
            name={"boxHeading3"}
            value={inputValues.boxHeading3}
            onChange={handleChangeInput}
          />
        </Grid>
      </Grid>
      <Box width={150} height={40} margin={"10px 0 0 auto"}>
        <CustomButton btnLabel={"Update"} handlePressBtn={handleUpdate} />
      </Box>
    </DrawerComponent>
  );
}

export default Winner;
