import React, { useState, useEffect } from "react";
import { changePassword } from "../../../services/adminAuthentication";
import BootstrapDialog from "../../../components/dialog/index";
import DialogHeader from "../../../components/dialog/dialogHeader";
import { DialogContent, Grid } from "@mui/material";
import InputLablessss from "../../../components/TextLabel";
import TextInput from "../../../components/TextInput";
import DialogFooter from "../../../components/dialog/dialogFooter";
import { updatePackages } from "../../../services/packages";

export default function PackageEditDialog({
  open,
  dialogData,
  inputValue,
  handleClose,
  setInputValue,
  setIsLoading,
  handleSnackbarVal,
  getPackages,
}) {
  useEffect(() => {
    if (dialogData) {
      setInputValue({
        type: dialogData?.type,
        comments: dialogData?.comments,
        price: dialogData?.price,
      });
    }
  }, [dialogData]);

  const handleChangeInput = (e) => {
    setInputValue({
      ...inputValue,
      [e.target.name]: e.target.value,
    });
  };

  const handleBtn = async () => {
    const { type, comments, price } = inputValue;
    if (!type) {
      handleSnackbarVal(true, "error", "Package Name is Required");
    } else if (!comments) {
      handleSnackbarVal(true, "error", "Comments Count is Required");
    } else if (!price) {
      handleSnackbarVal(true, "error", "Price is Required");
    } else {
      let payload = {
        type,
        comments,
        price,
      };
      setIsLoading(true);
      updatePackages(dialogData?._id, payload)
        .then((res) => {
          setIsLoading(false);
          if (res?.data?.status == "ok") {
            handleSnackbarVal(true, "success", res?.data?.message);
            handleClose();
            getPackages();
          } else {
            handleSnackbarVal(true, "error", res?.data?.message);
          }
        })
        .catch((err) => {
          setIsLoading(false);
          handleSnackbarVal(true, "error", err?.message);
        });
    }
  };

  return (
    <div>
      <BootstrapDialog onClose={handleClose} open={open}>
        <DialogHeader title={"Edit Package"} handleClose={handleClose} />
        <DialogContent dividers>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <InputLablessss label={"Package Name"} />
              <TextInput
                type={"text"}
                placeholder={"Package Name"}
                // size="small"
                name="type"
                value={inputValue.type}
                onChange={handleChangeInput}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <InputLablessss label={"Comments"} />
              <TextInput
                type={"text"}
                placeholder={"Comments"}
                name="comments"
                value={inputValue.comments}
                onChange={handleChangeInput}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <InputLablessss label={"Price"} />
              <TextInput
                type={"text"}
                placeholder={"Price"}
                name="price"
                value={inputValue.price}
                onChange={handleChangeInput}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogFooter
          type={"edit"}
          handleUpdate={handleBtn}
          handleClose={handleClose}
        />
      </BootstrapDialog>
    </div>
  );
}
