import Api from "./index";
import { endPoints, requestType } from "../constants/variables";

export const fetchHome = () => {
  return Api(endPoints.getHome, null, requestType.GET);
};

export const updateHome = (id, params) => {
  return Api(`${endPoints.updateHome}/${id}`, params, requestType.PUT);
};
