import Api from "./index";
import { endPoints, requestType } from "../constants/variables";

export const fetchPrivacyAndTerms = () => {
  return Api(endPoints.getPrivacy, null, requestType.GET);
};

export const updatePrivacyAndTerms = (id, params) => {
  return Api(`${endPoints.updatePrivacy}/${id}`, params, requestType.PUT);
};
