import React, { useEffect, useState } from "react";
import DrawerComponent from "../../../components/NavigationDrawer";
import { Box, Grid, Typography } from "@mui/material";
import InputLablessss from "../../../components/TextLabel";
import TextInput from "../../../components/TextInput";
import CustomButton from "../../../components/CustomButton";
import SnackbarAlert from "../../../components/SnackbarAlert";
import Loader from "../../../components/Loader";
import {
  fetchVideoInfo,
  updateVideoInfo,
} from "../../../services/videoInfoApi";

function VideoInformation() {
  const [isLoading, setIsLoading] = useState(false);
  const [videoInfo, setVideoInfo] = useState(null);
  const [heading, setHeading] = useState("");
  const [inputVal, setInputVal] = useState({
    heading: "",
    dialogTitle: "",
    dialogText: "",
  });
  const [snackbarProps, setSnackbarProps] = useState({
    open: false,
    type: "",
    message: "",
  });

  const handleSnackbarVal = (open, type, message) => {
    setSnackbarProps({
      open,
      type,
      message,
    });
  };

  const handleCloseSnackbar = () => {
    handleSnackbarVal(false, "", "");
  };

  useEffect(() => {
    getVideoInfo();
  }, []);

  const getVideoInfo = () => {
    setIsLoading(true);
    fetchVideoInfo()
      .then((res) => {
        setIsLoading(false);
        if (res?.data?.status == "ok") {
          let data = res?.data?.data;
          setHeading(data?.heading);
          setInputVal({
            heading: data?.heading,
            dialogTitle: data?.dialogTitle,
            dialogText: data?.dialogText,
          });
          setVideoInfo(data);
          console.log(data, "datadatadata");
        } else {
          handleSnackbarVal(true, "error", res?.data?.message);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        handleSnackbarVal(true, "error", err?.message);
      });
  };

  const handleChangeInput = (e) => {
    const { name, value } = e.target;
    setInputVal({
      ...inputVal,
      [name]: value,
    });
  };

  const handleUpdate = () => {
    const { heading, dialogTitle, dialogText } = inputVal;
    if (!heading) {
      handleSnackbarVal(true, "error", "Heading is required");
    } else if (!dialogTitle) {
      handleSnackbarVal(true, "error", "Dialog Heading is required");
    } else if (!dialogText) {
      handleSnackbarVal(true, "error", "Dialog Text is required");
    } else {
      setIsLoading(true);
      let payload = {
        heading,
        dialogTitle,
        dialogText,
      };
      updateVideoInfo(videoInfo?._id, payload)
        .then((res) => {
          setIsLoading(false);
          if (res?.data?.status == "ok") {
            handleSnackbarVal(true, "success", res?.data?.message);
            getVideoInfo();
          } else {
            handleSnackbarVal(true, "error", res?.data?.message);
          }
        })
        .catch((err) => {
          setIsLoading(false);
          handleSnackbarVal(true, "error", err?.message);
        });
    }
  };

  return (
    <DrawerComponent>
      <SnackbarAlert
        snackbarProps={snackbarProps}
        handleCloseSnackbar={handleCloseSnackbar}
      />
      <Loader isloading={isLoading} />
      <Typography variant={"h6"} fontSize={22} fontWeight={600}>
        Video Information
      </Typography>
      <Grid container spacing={2} alignItems={"end"}>
        <Grid item xs={12} md={4}>
          <InputLablessss label={"Heading"} />
          <TextInput
            type={"text"}
            value={inputVal?.heading}
            name={"heading"}
            onChange={handleChangeInput}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <InputLablessss label={"Dialog Heading"} />
          <TextInput
            type={"text"}
            value={inputVal?.dialogTitle}
            name={"dialogTitle"}
            onChange={handleChangeInput}
          />
        </Grid>{" "}
        <Grid item xs={12} md={4}>
          <InputLablessss label={"Dialog text"} />
          <TextInput
            type={"text"}
            value={inputVal?.dialogText}
            name={"dialogText"}
            onChange={handleChangeInput}
          />
        </Grid>
        <Grid item xs={12} md={2}>
          <Box width={"100%"} height={40}>
            <CustomButton btnLabel={"Update"} handlePressBtn={handleUpdate} />
          </Box>
        </Grid>
      </Grid>
    </DrawerComponent>
  );
}

export default VideoInformation;
