import { DialogContent, Grid } from "@mui/material";
import React, { useEffect } from "react";
import TextInput from "../../../components/TextInput";
import InputLablessss from "../../../components/TextLabel";
import DialogFooter from "../../../components/dialog/dialogFooter";
import DialogHeader from "../../../components/dialog/dialogHeader";
import BootstrapDialog from "../../../components/dialog/index";
import { createHelp, updateHelpById } from "../../../services/help";

export default function AddHelpDialog({
  open,
  type,
  dialogData,
  handleClose,
  inputValue,
  setInputValue,
  handleSnackbarVal,
  setIsLoading,
  fetchAllHelps,
}) {
  useEffect(() => {
    if (dialogData) {
      setInputValue({
        helpText: dialogData?.title,
        contentText: dialogData?.content,
      });
    }
  }, [dialogData]);

  console.log(type, "dasdjasdjdjhasddasd");

  //set input value function

  const handleChangeInput = (e) => {
    setInputValue({
      ...inputValue,
      [e.target.name]: e.target.value,
    });
  };

  //add edit button

  const handleBtn = () => {
    if (!inputValue?.helpText) {
      handleSnackbarVal(true, "error", "This field is required");
    } else if (!inputValue?.contentText) {
      handleSnackbarVal(true, "error", "This field is required");
    } else {
      let payload = {
        title: inputValue?.helpText,
        content: inputValue?.contentText,
      };

      if (type === "add") {
        setIsLoading(true);
        createHelp(payload)
          .then((response) => {
            setIsLoading(false);
            if (response?.data?.status === "ok") {
              handleClose();
              handleSnackbarVal(true, "success", response?.data?.message);
              fetchAllHelps();
            } else {
              handleSnackbarVal(true, "error", response?.data?.message);
            }
          })
          .catch((error) => {
            setIsLoading(false);
            handleSnackbarVal(true, "error", "Something went wrong");
          });
      } else {
        setIsLoading(true);
        updateHelpById(dialogData?._id, payload)
          .then((response) => {
            setIsLoading(false);
            if (response?.data?.status === "ok") {
              handleClose();
              handleSnackbarVal(true, "success", response?.data?.message);
              fetchAllHelps();
            } else {
              handleSnackbarVal(true, "error", response?.data?.message);
            }
          })
          .catch((error) => {
            setIsLoading(false);
            handleSnackbarVal(true, "error", "Something went wrong");
          });
      }
    }
  };

  return (
    <div>
      <BootstrapDialog onClose={handleClose} open={open}>
        <DialogHeader
          title={type === "add" ? "Add Help" : "Edit Help"}
          handleClose={handleClose}
        />
        <DialogContent dividers>
          <Grid spacing={3}>
            <Grid item xs={12}>
              <InputLablessss label={"Help"} />
              <TextInput
                type={"text"}
                placeholder={"Enter Help"}
                name="helpText"
                value={inputValue.helpText}
                onChange={handleChangeInput}
              />
            </Grid>
            <Grid item xs={12}>
              <InputLablessss label={"Content"} />
              <TextInput
                type={"text"}
                placeholder={"Enter content"}
                multiline={true}
                rows={3}
                name="contentText"
                value={inputValue.contentText}
                onChange={handleChangeInput}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogFooter
          type={type}
          handleUpdate={handleBtn}
          handleCreate={handleBtn}
          handleClose={handleClose}
        />
      </BootstrapDialog>
    </div>
  );
}
