import React, { useEffect, useState } from "react";
import DrawerComponent from "../../../components/NavigationDrawer";
import { Box, Grid, Typography } from "@mui/material";
import InputLablessss from "../../../components/TextLabel";
import CustomButton from "../../../components/CustomButton";
import TextInput from "../../../components/TextInput";
import SnackbarAlert from "../../../components/SnackbarAlert";
import Loader from "../../../components/Loader";
import { fetchSetting, updateSetting } from "../../../services/settingApi";

function Settings() {
  const [isLoading, setIsLoading] = useState(false);
  const [settingData, setSettingData] = useState(null);
  const [inputValues, setInputValues] = useState({
    heading1: "",
    heading2: "",
    heading3: "",
    heading4: "",
    btnText: "",
  });
  const [snackbarProps, setSnackbarProps] = useState({
    open: false,
    type: "",
    message: "",
  });

  const handleSnackbarVal = (open, type, message) => {
    setSnackbarProps({
      open,
      type,
      message,
    });
  };

  const handleCloseSnackbar = () => {
    handleSnackbarVal(false, "", "");
  };

  useEffect(() => {
    getSettingData();
  }, []);

  const getSettingData = () => {
    setIsLoading(true);
    fetchSetting()
      .then((res) => {
        setIsLoading(false);
        if (res?.data?.status == "ok") {
          let data = res?.data?.data;
          setInputValues({
            heading1: data?.heading1,
            heading2: data?.heading2,
            heading3: data?.heading3,
            heading4: data?.heading4,
            btnText: data?.btnText,
          });
          setSettingData(data);
          console.log(data, "datadatadata");
        } else {
          handleSnackbarVal(true, "error", res?.data?.message);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        handleSnackbarVal(true, "error", err?.message);
      });
  };

  const handleChangeInput = (e) => {
    const { value, name } = e.target;
    setInputValues({
      ...inputValues,
      [name]: value,
    });
  };

  const handleUpdate = () => {
    const { heading1, heading2, heading3, heading4, btnText } = inputValues;
    if (!heading1) {
      handleSnackbarVal(true, "error", "Heading one is required");
    } else if (!heading2) {
      handleSnackbarVal(true, "error", "Heading two is required");
    } else if (!heading3) {
      handleSnackbarVal(true, "error", "Heading three is required");
    } else if (!heading4) {
      handleSnackbarVal(true, "error", "Heading Four is required");
    } else if (!btnText) {
      handleSnackbarVal(true, "error", "Button Text is required");
    } else {
      setIsLoading(true);
      let payload = {
        heading1,
        heading2,
        heading3,
        heading4,
        btnText,
      };
      updateSetting(settingData?._id, payload)
        .then((res) => {
          setIsLoading(false);
          if (res?.data?.status == "ok") {
            handleSnackbarVal(true, "success", res?.data?.message);
            getSettingData();
          } else {
            handleSnackbarVal(true, "error", res?.data?.message);
          }
        })
        .catch((err) => {
          setIsLoading(false);
          handleSnackbarVal(true, "error", err?.message);
        });
    }
  };
  return (
    <DrawerComponent>
      <SnackbarAlert
        snackbarProps={snackbarProps}
        handleCloseSnackbar={handleCloseSnackbar}
      />
      <Loader isloading={isLoading} />
      <Typography variant={"h6"} fontSize={22} fontWeight={600}>
        Setting
      </Typography>
      <Grid container spacing={2} alignItems={"end"}>
        <Grid item xs={12} md={4}>
          <InputLablessss label={"Box Heading 1"} />
          <TextInput
            type={"text"}
            name={"heading1"}
            value={inputValues.heading1}
            onChange={handleChangeInput}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <InputLablessss label={"Box Heading 2"} />
          <TextInput
            type={"text"}
            name={"heading2"}
            value={inputValues.heading2}
            onChange={handleChangeInput}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <InputLablessss label={"Box Heading 3"} />
          <TextInput
            type={"text"}
            name={"heading3"}
            value={inputValues.heading3}
            onChange={handleChangeInput}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <InputLablessss label={"Box Heading 4"} />
          <TextInput
            type={"text"}
            name={"heading4"}
            value={inputValues.heading4}
            onChange={handleChangeInput}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <InputLablessss label={"Button Text"} />
          <TextInput
            type={"text"}
            name={"btnText"}
            value={inputValues.btnText}
            onChange={handleChangeInput}
          />
        </Grid>
        <Grid item xs={12} md={2}>
          <Box width={"100%"} height={40}>
            <CustomButton btnLabel={"Update"} handlePressBtn={handleUpdate} />
          </Box>
        </Grid>
      </Grid>
    </DrawerComponent>
  );
}

export default Settings;
