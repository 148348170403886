import React, { useState } from "react";
import { Typography, Grid, Container } from "@mui/material";
import { allColors } from "../../constants/Color";
import InputLablessss from "../../components/TextLabel";
import CustomButton from "../../components/CustomButton";
import TextInput from "../../components/TextInput";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { useNavigate } from "react-router-dom";
import SnackbarAlert from "../../components/SnackbarAlert";
import Loader from "../../components/Loader";
import AuthBackground from "../../components/AuthBackground";
import { forgotPassword } from "../../services/adminAuthentication";

const ForgotPassword = () => {
  const navigate = useNavigate();
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const [isloading, setIsLoading] = useState(false);
  const [inputValues, setInputValues] = useState({
    username: "",
    email: "",
    newPassowrd: "",
  });

  const [snackbarProps, setSnackbarProps] = useState({
    open: false,
    type: "",
    message: "",
  });

  const handleSnackbarVal = (open, type, message) => {
    setSnackbarProps({
      open,
      type,
      message,
    });
  };

  const handleCloseSnackbar = () => {
    handleSnackbarVal(false, "", "");
  };

  const handleChangeInput = (event) => {
    const { name, value } = event.target;
    setInputValues({
      ...inputValues,
      [name]: value,
    });
  };

  const handleForgotPassword = () => {
    if (inputValues.username == "") {
      handleSnackbarVal(true, "error", "Username is required");
    } else if (inputValues.email == "") {
      handleSnackbarVal(true, "error", "Email is required");
    } else if (inputValues.newPassowrd == "") {
      handleSnackbarVal(true, "error", "Password is required");
    } else if (!emailRegex.test(inputValues.email)) {
      handleSnackbarVal(true, "error", "Invalid email format");
    } else {
      let payload = {
        name: inputValues.username,
        email: inputValues.email,
        password: inputValues.newPassowrd,
      };
      setIsLoading(true);
      forgotPassword(payload)
        .then((ress) => {
          console.log(ress, "SSSSSSSSSSSSSSSSSS");
          setIsLoading(false);
          if (ress?.data?.status == "ok") {
            handleSnackbarVal(true, "success", ress?.data?.message);
            setInputValues({
              username: "",
              email: "",
              newPassowrd: "",
            });
            setTimeout(() => {
              navigate("/login");
            }, 2000);
          } else {
            handleSnackbarVal(true, "error", ress?.data?.message);
          }
        })
        .catch((err) => {
          setIsLoading(false);
          handleSnackbarVal(true, "success", err?.message);
        });
    }
  };

  return (
    <AuthBackground>
      <SnackbarAlert
        snackbarProps={snackbarProps}
        handleCloseSnackbar={handleCloseSnackbar}
      />
      <Loader isloading={isloading} />

      <Container
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          maxWidth: "390px !important",
        }}
      >
        <Typography
          variant={"h2"}
          fontSize={30}
          fontWeight={600}
          marginRight={"auto"}
        >
          Reset Password
        </Typography>
        <Typography variant={"body1"} fontSize={14} marginRight={"auto"}>
          Enter your email and username to reset Password
        </Typography>
        <Grid container mt={3}>
          <Grid item xs={12} md={12}>
            <InputLablessss label={"User Name"} />
            <TextInput
              type={"text"}
              placeholder={"username"}
              name={"username"}
              value={inputValues.username}
              onChange={handleChangeInput}
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <InputLablessss label={"Email"} />
            <TextInput
              type={"email"}
              placeholder={"email"}
              name={"email"}
              value={inputValues.email}
              onChange={handleChangeInput}
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <InputLablessss label={"Password"} />
            <TextInput
              type={"password"}
              placeholder={"password"}
              name={"newPassowrd"}
              value={inputValues.newPassowrd}
              onChange={handleChangeInput}
            />
          </Grid>
        </Grid>
        <Typography
          variant={"body2"}
          fontSize={13}
          fontWeight={600}
          color={allColors.black}
          margin={"10px auto 10px 0px"}
          sx={{
            cursor: "pointer",
            display: "flex",
            alignItems: "center",
            gap: "5px",
          }}
          onClick={() => navigate("/login")}
        >
          <KeyboardBackspaceIcon />
          Go Back
        </Typography>
        <CustomButton
          btnLabel={"Reset Password"}
          height={45}
          handlePressBtn={handleForgotPassword}
        />
      </Container>
    </AuthBackground>
  );
};

export default ForgotPassword;
