import { Route, Routes } from "react-router-dom";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { AdminRoutes, PagesRoutes } from "./routes/allRoutes";
import AppProvider from "./context";
import { ProtectedLayout } from "./components/protectedLayout";
import Login from "./containers/auth/Login";
import ForgotPassword from "./containers/auth/ForgotPassword";

function App() {
  const theme = createTheme({
    typography: {
      fontFamily: ["Inter", "Lato", "sans-serif"].join(","),
    },
    button: {
      fontFamily: ["Inter", "Lato", "sans-serif"].join(","),
    },
  });

  const isUserAuthenticate = () => {
    const userData = JSON.parse(localStorage.getItem("user"));
    if (userData) {
      return userData;
    } else {
      return null;
    }
  };
  const isAuthenticated = isUserAuthenticate();

  return (
    <ThemeProvider theme={theme}>
      <AppProvider>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/forgotPassword" element={<ForgotPassword />} />
          <Route path="/" element={<ProtectedLayout />}>
            {isAuthenticated?.role === "Admin"
              ? PagesRoutes.map(({ component: Component, path, exact, id }) => {
                  return (
                    <Route
                      key={id}
                      path={path}
                      exact={exact}
                      element={Component}
                    />
                  );
                })
              : null}
            {isAuthenticated?.role === "Admin"
              ? AdminRoutes.map(({ component: Component, path, exact, id }) => {
                  return (
                    <Route
                      key={id}
                      path={path}
                      exact={exact}
                      element={Component}
                    />
                  );
                })
              : null}
          </Route>
        </Routes>
      </AppProvider>
    </ThemeProvider>
  );
}

export default App;
