import Api from "./index";
import { endPoints, requestType } from "../constants/variables";

export const fetchVideoInfo = () => {
  return Api(endPoints.getVideoInfo, null, requestType.GET);
};

export const updateVideoInfo = (id, params) => {
  return Api(`${endPoints.updateVideoInfo}/${id}`, params, requestType.PUT);
};
