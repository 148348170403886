import React, { useContext, useState } from "react";
import PropTypes from "prop-types";

import {
  AppBar,
  Box,
  CssBaseline,
  Divider,
  Drawer,
  IconButton,
  ListItemText,
  List,
  ListItemButton,
  ListItemIcon,
  Toolbar,
  Icon,
  ListItem,
  Typography,
} from "@mui/material";
import { useNavigate, useLocation } from "react-router";
import { CustomBar, DrwerName } from "./DrawerHeader";
import KeyboardArrowDown from "@mui/icons-material/KeyboardArrowDown";
import { AdminRoutes, PagesRoutes } from "../routes/allRoutes";
import NavigationDrawerStyle from "./Style";
import { AppContext } from "../context";

const drawerWidth = 240;

function DrawerComponent(props) {
  const { window } = props;
  const navigate = useNavigate();
  const classes = NavigationDrawerStyle();
  const [mobileOpen, setMobileOpen] = useState(false);
  //   const [openTab, setOpenTab] = useState(false);
  const userData = JSON.parse(localStorage.getItem("user"));
  const { openTab, setOpenTab } = useContext(AppContext);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const navigateLink = (route) => {
    navigate(`/${route}`);
  };

  const drawer = (
    <div>
      <DrwerName />
      <Divider />
      <List sx={{ paddingBottom: "50px" }}>
        <>
          {userData?.role === "Admin" ? (
            <>
              <ListItem
                button
                className={classes.listItemStyle}
                onClick={() => setOpenTab(!openTab)}
              >
                <ListItemIcon className="">
                  <Icon>add_circle_outline</Icon>
                </ListItemIcon>
                <ListItemText primary={"Pages"} />
                <KeyboardArrowDown
                  sx={{
                    mr: -1,
                    transform: openTab ? "rotate(-180deg)" : "rotate(180)",
                    transition: "0.2s",
                  }}
                />
              </ListItem>
              {openTab &&
                PagesRoutes?.map((route) => (
                  <ListItem
                    button
                    key={route.id}
                    className={classes.listItemStyle}
                    onClick={() => navigateLink(route.path)}
                  >
                    <ListItemIcon className="">
                      <Icon>{route.icon}</Icon>
                    </ListItemIcon>
                    <ListItemText primary={route.name} />
                  </ListItem>
                ))}
            </>
          ) : null}
          {userData?.role === "Admin" &&
            AdminRoutes?.map((route) => (
              <ListItem
                button
                key={route.id}
                className={classes.listItemStyle}
                onClick={() => navigateLink(route.path)}
              >
                <ListItemIcon className="">
                  <Icon>{route.icon}</Icon>
                </ListItemIcon>
                <ListItemText
                  primary={
                    route.name.length > 20
                      ? route.name.substring(0, 24) + "...."
                      : route.name
                  }
                />
              </ListItem>
            ))}
        </>
      </List>
    </div>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <CustomBar handleDrawerToggle={handleDrawerToggle} />
      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: "none", sm: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: { xs: "20px 20px", md: "20px 15px" },
          backgroundColor: "#F5F5F5",
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          overflowX: "auto",
          height: "100vh",
        }}
      >
        <Toolbar />
        {props.children}
      </Box>
    </Box>
  );
}

DrawerComponent.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default DrawerComponent;
