import { Box, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import Loader from "../../../components/Loader";
import DrawerComponent from "../../../components/NavigationDrawer";
import SnackbarAlert from "../../../components/SnackbarAlert";
import CustomButton from "../../../components/CustomButton";
import DynamicTable from "../../../components/dynamicTable";
import AddHelpDialog from "./addHelp";
import { deleteHelp, getAllHep } from "../../../services/help";
import DeleteHelper from "./../../../components/alert/deleteHelper";

const headerData = [
  {
    id: "helpType",
    numeric: false,
    disablePadding: true,
    label: "Title",
  },
  {
    id: "content",
    numeric: false,
    disablePadding: true,
    label: "Content",
  },
  {
    id: "action",
    numeric: false,
    disablePadding: true,
    label: "Action",
  },
];

const diplayRows = ["title", "content", "actions"];

function HelpCenter() {
  const [type, setType] = useState("add");
  const [isLoading, setIsLoading] = useState(false);
  const [dialogData, setDialogData] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [rowsData, setRowsData] = useState([]);
  const [inputValue, setInputValue] = useState({
    helpText: "",
    contentText: "",
  });

  const [snackbarProps, setSnackbarProps] = useState({
    open: false,
    type: "",
    message: "",
  });

  //open snackbar

  const handleSnackbarVal = (open, type, message) => {
    setSnackbarProps({
      open,
      type,
      message,
    });
  };

  //close snackbar

  const handleCloseSnackbar = () => {
    handleSnackbarVal(false, "", "");
  };

  useEffect(() => {
    fetchAllHelps();
  }, []);

  const fetchAllHelps = () => {
    setIsLoading(true);
    getAllHep()
      .then((response) => {
        setIsLoading(false);
        if (response?.data.status == "ok") {
          let data = response?.data?.data.slice().reverse();
          setRowsData(data);
        } else {
          console.log("lund");
          handleSnackbarVal(true, "error", "Something went wrong");
        }
      })
      .catch((error) => {
        setIsLoading(false);
        handleSnackbarVal(true, "error", "jj went wrong");
      });
  };

  //open add dialog

  const handleOpenDialog = () => {
    setOpenDialog(true);
    setType("add");
  };

  //open edit dialog

  const handleOpenEditDialog = (row) => {
    setType("edit");
    setDialogData(row);
    setOpenDialog(true);
  };

  //close dialog

  const handleCloseDialog = () => {
    setType("add");
    setOpenDialog(null);
    setDialogData(false);
    setInputValue({
      helpText: "",
      contentText: "",
    });
  };

  //delete data

  const handleDelete = (row) => {
    DeleteHelper(true, row?._id, deleteHelp, fetchAllHelps);
  };

  return (
    // navigation drawer

    <DrawerComponent>
      {/* snacbar alert */}

      <SnackbarAlert
        snackbarProps={snackbarProps}
        handleCloseSnackbar={handleCloseSnackbar}
      />

      {/* loader */}

      <Loader isloading={isLoading} />

      <Typography variant={"h6"} fontSize={22} fontWeight={600}>
        Help Center
      </Typography>

      <Box width={150} height={40} margin={"10px 0px 10px auto"}>
        <CustomButton btnLabel={"Add"} handlePressBtn={handleOpenDialog} />
      </Box>

      {/* dynamic table */}

      <DynamicTable
        headerData={headerData}
        bodyRows={rowsData}
        displayData={diplayRows}
        showEdit={true}
        showDelete={true}
        openEditDialog={handleOpenEditDialog}
        handleDelete={handleDelete}
      />

      {/* dialog */}

      <AddHelpDialog
        open={openDialog}
        type={type}
        handleClose={handleCloseDialog}
        dialogData={dialogData}
        inputValue={inputValue}
        setInputValue={setInputValue}
        handleSnackbarVal={handleSnackbarVal}
        setIsLoading={setIsLoading}
        fetchAllHelps={fetchAllHelps}
      />
    </DrawerComponent>
  );
}

export default HelpCenter;
