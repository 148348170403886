import { makeStyles } from "@mui/styles";
// import { allColors } from "../constants/colors";

const drawerWidth = 240;
const NavigationDrawerStyle = makeStyles((theme) => ({
  appBar: {
    backgroundColor: "#fff !important",
    color: "#000 !important",
    boxShadow:
      "0px -6.457657814025879px 14.529728889465332px 0px rgba(0, 0, 0, 0.13), 0px -1.210810899734497px 3.632432222366333px 0px rgba(0, 0, 0, 0.11) !important",
    "&.MuiPaper-root .MuiToolbar-root": {
      height: "65px",
    },
    "&.MuiPaper-root .MuiToolbar-root .MuiFormControl-root .MuiOutlinedInput-root ":
      {
        "& fieldset": {
          border: "none",
        },
      },
  },
  logoutDialogStyle: {
    "& .MuiPaper-root": {
      width: "200px",
      position: "absolute",
      top: "33px",
      right: "0px",
      borderRadius: "10px",
    },
    "& .MuiPaper-root .MuiDialogTitle-root": {
      padding: "10px",
      backgroundColor: "blue",
      color: "#fff",
      fontSize: "16px",
    },
    "& .MuiPaper-root .MuiDialogContent-root ": {
      padding: "4px",
    },
    "& .MuiPaper-root .MuiDialogActions-root": {
      justifyContent: "flex-start",
      padding: "5px",
    },
  },

  notificationDialogStyle: {
    "& .MuiDialog-paper ": {
      width: "300px",
      position: "absolute",
      top: "12px",
      right: "14%",
      [theme.breakpoints.down(900)]: {
        right: "1%",
      },
      borderRadius: "10px",
    },
    "& .MuiDialog-paper  .MuiDialogTitle-root": {
      padding: "10px",
      backgroundColor: "blue",
      color: "#fff",
      display: "flex",
      justifyContent: "space-between",
    },
    "& .MuiDialog-paper  .MuiDialogContent-root ": {
      padding: "0px",
    },
    "& .MuiDialog-paper  .MuiDialogActions-root": {
      justifyContent: "flex-start",
      padding: "5px",
    },
  },

  noticationTabStyle: {
    "&.MuiPaper-root": {
      // backgroundColor: "#fff !important",
      color: "#000 !important",
    },
    "& .MuiTabs-root": {
      minHeight: "30px ",
    },
    "& .MuiTab-root": {
      minHeight: "30px",
      fontSize: 13,
      textTransform: "capitalize",
      // backgroundColor: "#D9D9D9",
      backgroundColor: "blue",
      color: "#dfdfdfdb",
      opacity: 1,
      padding: "10px 16px",
      fontWeight: "400",
    },
    "& .Mui-selected ": {
      backgroundColor: "blue",
      fontWeight: "600",
      color: "#fff",
    },
    "& .MuiTabs-indicator": {
      height: "2px !important",
      backgroundColor: "#fff",
    },
    "&.MuiPaper-root .react-swipeable-view-container .MuiBox-root ": {
      padding: "10px 0px !important",
    },
  },
  listItemStyle: {
    margin: "0px 0px !important",
    paddingLeft: "30px !important",
    borderRadius: "10px !important",
    // "&.MuiListItem-root .MuiListItemButton-root": {
    //   padding: " 8px 0px 8px 10px",
    //   // backgroundColor: "brown",
    //   marginLeft: "20px",
    //   marginRight: " 5px",
    //   // borderRadius: "6px",
    //   // color: "#fff",
    //   "&:hover ": {
    //     // backgroundColor: "none",
    //     borderRadius: "6px",
    //   },
    // },
    "&.MuiListItem-root .activeTab": {
      // padding: " 8px 0px 8px 10px",
      // marginLeft: "20px",
      // marginRight: " 5px",
      borderRadius: "6px",
      color: "#fff",
    },
    "&.MuiListItem-root .MuiListItemIcon-root": {
      minWidth: "40px",
    },
    "&.MuiListItem-root .MuiListItemIcon-root .activeIcon": {
      color: "#fff",
    },
    "&.MuiListItem-root .MuiListItemText-root .MuiTypography-body1": {
      fontSize: "15px",
      fontWeight: 400,
      color: "#8d97ad",
    },
  },
}));

export default NavigationDrawerStyle;
