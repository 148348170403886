import Api from "./index";
import { endPoints, requestType } from "../constants/variables";

export const loginAdmin = (params) => {
  return Api(endPoints.loginAdmin, params, requestType.POST);
};

export const forgotPassword = (params) => {
  return Api(endPoints.forgotPasswordAdmin, params, requestType.POST);
};

export const updateAdminName = (id, params) => {
  return Api(`${endPoints.updateAdminName}/${id}`, params, requestType.PUT);
};

export const changePassword = (params) => {
  return Api(endPoints.changePasswordAdmin, params, requestType.POST);
};

export const updateAdmin = (id, params) => {
  return Api(`${endPoints.updateAdminName}/${id}`, params, requestType.PUT);
};
