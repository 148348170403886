import React, { useEffect, useState } from "react";
import DrawerComponent from "../../../components/NavigationDrawer";
import { Box, Grid, Typography } from "@mui/material";
import InputLablessss from "../../../components/TextLabel";
import TextInput from "../../../components/TextInput";
import CustomButton from "../../../components/CustomButton";
import SnackbarAlert from "../../../components/SnackbarAlert";
import Loader from "../../../components/Loader";
import {
  fetchCertificate,
  updateCertificate,
} from "../../../services/certificate";

function Certificate() {
  const [isLoading, setIsLoading] = useState(false);
  const [certificateData, setCertificateData] = useState(null);
  const [heading, setHeading] = useState("");
  const [snackbarProps, setSnackbarProps] = useState({
    open: false,
    type: "",
    message: "",
  });

  const handleSnackbarVal = (open, type, message) => {
    setSnackbarProps({
      open,
      type,
      message,
    });
  };

  const handleCloseSnackbar = () => {
    handleSnackbarVal(false, "", "");
  };

  useEffect(() => {
    getCertificateData();
  }, []);

  const getCertificateData = () => {
    setIsLoading(true);
    fetchCertificate()
      .then((res) => {
        setIsLoading(false);
        if (res?.data?.status == "ok") {
          let data = res?.data?.data;
          setHeading(data?.heading);
          setCertificateData(data);
          console.log(data, "datadatadata");
        } else {
          handleSnackbarVal(true, "error", res?.data?.message);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        handleSnackbarVal(true, "error", err?.message);
      });
  };

  const handleUpdate = () => {
    if (!heading) {
      handleSnackbarVal(true, "error", "Heading one is required");
    } else {
      setIsLoading(true);
      let payload = {
        heading,
      };
      updateCertificate(certificateData?._id, payload)
        .then((res) => {
          setIsLoading(false);
          if (res?.data?.status == "ok") {
            handleSnackbarVal(true, "success", res?.data?.message);
            getCertificateData();
          } else {
            handleSnackbarVal(true, "error", res?.data?.message);
          }
        })
        .catch((err) => {
          setIsLoading(false);
          handleSnackbarVal(true, "error", err?.message);
        });
    }
  };

  return (
    <DrawerComponent>
      <SnackbarAlert
        snackbarProps={snackbarProps}
        handleCloseSnackbar={handleCloseSnackbar}
      />
      <Loader isloading={isLoading} />
      <Typography variant={"h6"} fontSize={22} fontWeight={600}>
        Certificate Page
      </Typography>
      <Grid container spacing={2} alignItems={"end"} mt={2}>
        <Grid item xs={12} md={4}>
          <InputLablessss label={"Heading"} />
          <TextInput
            type={"text"}
            value={heading}
            onChange={(e) => setHeading(e.target.value)}
          />
        </Grid>
        <Grid item xs={12} md={2}>
          <Box width={"100%"} height={40}>
            <CustomButton btnLabel={"Update"} handlePressBtn={handleUpdate} />
          </Box>
        </Grid>
      </Grid>
    </DrawerComponent>
  );
}

export default Certificate;
