import Api from "./index";
import { endPoints, requestType } from "../constants/variables";

export const fetchCertificate = () => {
  return Api(endPoints.getCertificate, null, requestType.GET);
};

export const updateCertificate = (id, params) => {
  return Api(`${endPoints.updateCertificate}/${id}`, params, requestType.PUT);
};
