import React, { useState } from "react";
import { Box, Grid, Typography } from "@mui/material";
import DrawerComponent from "../../../components/NavigationDrawer";
import InputLablessss from "../../../components/TextLabel";
import TextInput from "../../../components/TextInput";
import CustomButton from "../../../components/CustomButton";
import SnackbarAlert from "../../../components/SnackbarAlert";
import Loader from "../../../components/Loader";
import { fetchHome, updateHome } from "../../../services/homeApi";
import { useEffect } from "react";

function Home() {
  const [isLoading, setIsLoading] = useState(false);
  const [homeData, setHomeData] = useState(null);
  const [inputValues, setInputValues] = useState({
    heading1: "",
    heading2: "",
    heading3: "",
  });
  const [snackbarProps, setSnackbarProps] = useState({
    open: false,
    type: "",
    message: "",
  });

  const handleSnackbarVal = (open, type, message) => {
    setSnackbarProps({
      open,
      type,
      message,
    });
  };

  const handleCloseSnackbar = () => {
    handleSnackbarVal(false, "", "");
  };

  useEffect(() => {
    getHomeData();
  }, []);

  const getHomeData = () => {
    setIsLoading(true);
    fetchHome()
      .then((res) => {
        setIsLoading(false);
        if (res?.data?.status == "ok") {
          let data = res?.data?.data;
          setInputValues({
            heading1: data?.heading1,
            heading2: data?.heading2,
            heading3: data?.heading3,
          });
          setHomeData(data);
          console.log(data, "datadatadata");
        } else {
          handleSnackbarVal(true, "error", res?.data?.message);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        handleSnackbarVal(true, "error", err?.message);
      });
  };

  const handleChangeInput = (e) => {
    const { value, name } = e.target;
    setInputValues({
      ...inputValues,
      [name]: value,
    });
  };

  const handleUpdate = () => {
    const { heading1, heading2, heading3 } = inputValues;
    if (!heading1) {
      handleSnackbarVal(true, "error", "Heading one is required");
    } else if (!heading2) {
      handleSnackbarVal(true, "error", "Heading two is required");
    } else if (!heading3) {
      handleSnackbarVal(true, "error", "Heading three is required");
    } else {
      setIsLoading(true);
      let payload = {
        heading1,
        heading2,
        heading3,
      };
      updateHome(homeData?._id, payload)
        .then((res) => {
          setIsLoading(false);
          if (res?.data?.status == "ok") {
            handleSnackbarVal(true, "success", res?.data?.message);
            getHomeData();
          } else {
            handleSnackbarVal(true, "error", res?.data?.message);
          }
        })
        .catch((err) => {
          setIsLoading(false);
          handleSnackbarVal(true, "error", err?.message);
        });
    }
  };
  
  return (
    <DrawerComponent>
      <SnackbarAlert
        snackbarProps={snackbarProps}
        handleCloseSnackbar={handleCloseSnackbar}
      />
      <Loader isloading={isLoading} />
      <Typography variant={"h6"} fontSize={22} fontWeight={600}>
        Home Page
      </Typography>
      <Grid container spacing={2} mt={2}>
        <Grid item xs={12} md={4}>
          <InputLablessss label={"Heading 1"} />
          <TextInput
            type={"text"}
            name={"heading1"}
            value={inputValues.heading1}
            onChange={handleChangeInput}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <InputLablessss label={"Heading 2"} />
          <TextInput
            type={"text"}
            name={"heading2"}
            value={inputValues.heading2}
            onChange={handleChangeInput}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <InputLablessss label={"Heading 3"} />
          <TextInput
            type={"text"}
            name={"heading3"}
            value={inputValues.heading3}
            onChange={handleChangeInput}
          />
        </Grid>
      </Grid>
      <Box width={150} height={40} margin={"10px 0px 10px auto"}>
        <CustomButton btnLabel={"Update"} handlePressBtn={handleUpdate} />
      </Box>
    </DrawerComponent>
  );
}

export default Home;
