import { InputAdornment, TextField } from "@mui/material";
import React from "react";
import { makeStyles } from "@mui/styles";
import useStyles from "../globalStyles";

function TextInput({
  placeholder,
  name,
  value,
  onChange,
  InputStartIcon,
  type,
  InputEndIcon,
  id,
  fullWidth,
  multiline,
  rows,
  disabled,
  readonly
}) {
  const classes = useStyles();
  return (
    <TextField
      id={id}
      type={type ? type : "text"}
      variant={"outlined"}
      size={"small"}
      fullWidth={fullWidth ? fullWidth : true}
      placeholder={placeholder}
      name={name}
      value={value}
      onChange={onChange}
      multiline={multiline ? multiline : false}
      className={classes.globalInput}
      rows={rows}
      disabled={disabled ? disabled : false}
      InputProps={{
        readOnly: readonly ? readonly : false,
        startAdornment: (
          <InputAdornment position="start">{InputStartIcon}</InputAdornment>
        ),
        endAdornment: (
          <InputAdornment position="end" sx={{ cursor: "pointer" }}>
            {InputEndIcon}
          </InputAdornment>
        ),
      }}
    />
  );
}

export default TextInput;
