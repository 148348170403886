import React, { useState, useEffect } from "react";
import SettingStyle from "./style";
import DrawerComponent from "../../../components/NavigationDrawer";
import Loader from "../../../components/Loader";
import SnackbarAlert from "../../../components/SnackbarAlert";
import { Button, Grid, Typography } from "@mui/material";
import InputLablessss from "../../../components/TextLabel";
import ChangePassDialog from "./changePassDialog";
import TextInput from "../../../components/TextInput";
import { updateAdmin } from "../../../services/adminAuthentication";

const Profile = () => {
  const classes = SettingStyle();
  const userData = JSON.parse(localStorage.getItem("user"));
  const [isLoading, setIsLoading] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [dialogData, setDialgData] = useState(null);
  const [userName, setUsername] = useState(userData.name);
  const [inputValue, setInputValue] = useState({
    password: "",
    newPassword: "",
  });
  const [snackbarProps, setSnackbarProps] = useState({
    open: false,
    type: "",
    message: "",
  });

  const handleSnackbarVal = (open, type, message) => {
    setSnackbarProps({
      open,
      type,
      message,
    });
  };

  const handleCloseSnackbar = () => {
    handleSnackbarVal(false, "", "");
  };

  const openEditDialog = (row) => {
    setOpenDialog(true);
    setDialgData(row);
  };

  const handleClose = () => {
    setOpenDialog(false);
    setDialgData(null);
    setInputValue({
      password: "",
      newPassword: "",
    });
  };

  const handleUpdateAdminName = () => {
    setIsLoading(true);
    updateAdmin(userData._id, { name: userName })
      .then((resss) => {
        setIsLoading(false);
        if (resss?.status == 200 || resss?.status == 201) {
          localStorage.setItem("user", JSON.stringify(resss?.data?.data));
          handleSnackbarVal(true, "success", resss?.data?.message);
        } else {
          handleSnackbarVal(true, "error", "Something went wrong");
        }
      })
      .catch((errr) => {
        setIsLoading(false);
        handleSnackbarVal(true, "error", errr?.message);
      });
  };

  return (
    <DrawerComponent>
      <Loader isloading={isLoading} />
      <SnackbarAlert
        snackbarProps={snackbarProps}
        handleCloseSnackbar={handleCloseSnackbar}
      />

      <Typography variant={"h5"} fontSize={30} fontWeight={700}>
        Profile
      </Typography>

      <Grid container spacing={2} alignItems={"flex-end"}>
        <Grid item xs={12} sm={3}>
          <InputLablessss label={"Admin Name"} />
          <TextInput
            placeholder={"User Name"}
            value={userName}
            onChange={(e) => setUsername(e.target.value)}
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <InputLablessss label={"Email"} />
          <TextInput readonly={true} value={userData?.email} />
        </Grid>
        <Grid item xs={12} sm={2}>
          <Button
            variant="contained"
            className={classes.updateBtn}
            onClick={handleUpdateAdminName}
          >
            Update
          </Button>
        </Grid>
        <Grid item xs={12} sm={2}>
          <Button
            variant="contained"
            className={classes.updateBtn}
            onClick={openEditDialog}
          >
            Change Password
          </Button>
        </Grid>
      </Grid>
      <ChangePassDialog
        open={openDialog}
        inputValue={inputValue}
        setInputValue={setInputValue}
        handleClose={handleClose}
        dialogData={dialogData}
        setIsLoading={setIsLoading}
        handleSnackbarVal={handleSnackbarVal}
      />
    </DrawerComponent>
  );
};

export default Profile;
