import * as React from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import LogoutIcon from "@mui/icons-material/Logout";
import { useNavigate } from "react-router-dom";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import SettingsIcon from "@mui/icons-material/Settings";
import NavigationDrawerStyle from "./Style";
import { useAuth } from "./hooks/useAuth";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function LogoutDialog({ open, handleClose }) {
  const classes = NavigationDrawerStyle();
  const userData = JSON.parse(localStorage.getItem("user"));
  console.log(userData, "dsdasdsdadasdaddsddad");
  const navigate = useNavigate();
  const {logout} = useAuth()

  const handleLogout = () => {
    handleClose();
    logout();
    navigate("/login");
  };
  return (
    <BootstrapDialog
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={open}
      className={classes.logoutDialogStyle}
      // BackdropProps={false}
      BackdropProps={{ open: false }}
    >
      <DialogContent dividers>
        {/* {userData?.role == "Vendor" && userData?.isVerified == false ? (
          <Button
            onClick={() => navigate("/")}
            sx={{
              width: "100%",
              textTransform: "capitalize",
              justifyContent: "flex-start",
              fontWeight: "400",
              // color: allColors.slateGray,
              color: "#8d97ad",
              "&:hover": {
                backgroundColor: "#fff",
              },
            }}
            startIcon={<PersonOutlineOutlinedIcon />}
          >
            Profile
          </Button>
        ) : (
          <Button
            onClick={() => navigate("/profile")}
            sx={{
              width: "100%",
              textTransform: "capitalize",
              justifyContent: "flex-start",
              fontWeight: "400",
              // color: allColors.slateGray,
              color: "#8d97ad",
              "&:hover": {
                backgroundColor: "#fff",
              },
            }}
            startIcon={<PersonOutlineOutlinedIcon />}
          >
            Profile
          </Button>
        )}
        {userData.role === "Admin" && (
          <Button
            onClick={() => navigate("/privacyPolicy")}
            sx={{
              width: "100%",
              textTransform: "capitalize",
              justifyContent: "flex-start",
              fontWeight: "400",
              // color: allColors.slateGray,
              color: "#8d97ad",
              "&:hover": {
                backgroundColor: "#fff",
              },
            }}
            startIcon={<SettingsIcon />}
          >
            Account Setting
          </Button>
        )} */}
      </DialogContent>
      <DialogActions>
        <Button
          sx={{
            width: "100%",
            textTransform: "capitalize",
            fontWeight: "400",
            justifyContent: "flex-start",
            // color: allColors.balticSea,

            color: "red",
            "&:hover": {
              backgroundColor: "#fff",
            },
          }}
          onClick={handleLogout}
          startIcon={<LogoutIcon />}
        >
          Logout
        </Button>
      </DialogActions>
    </BootstrapDialog>
  );
}
