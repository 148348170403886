import React, { useState, useEffect } from "react";
import { changePassword } from "../../../services/adminAuthentication";
import BootstrapDialog from "./../../../components/dialog/index";
import DialogHeader from "./../../../components/dialog/dialogHeader";
import { DialogContent, Grid } from "@mui/material";
import InputLablessss from "../../../components/TextLabel";
import TextInput from "../../../components/TextInput";
import DialogFooter from './../../../components/dialog/dialogFooter';

export default function ChangePassDialog({
  open,
  inputValue,
  setInputValue,
  handleClose,
  dialogData,
  setIsLoading,
  handleSnackbarVal,
}) {
  const userData = JSON.parse(localStorage.getItem("user"));
  console.log(inputValue, "INNNNNNNN");
  useEffect(() => {
    if (dialogData) {
    }
  }, [dialogData]);

  const handleChangeInput = (e) => {
    setInputValue({
      ...inputValue,
      [e.target.name]: e.target.value,
    });
  };

  const handleBtn = async () => {
    const { password, newPassword } = inputValue;
    if (password == "") {
      handleSnackbarVal(true, "error", "Please enter old password");
    } else if (newPassword == "") {
      handleSnackbarVal(true, "error", "Please enter new password");
    } else {
      let payload = {
        email: userData?.email,
        password,
        newPassword,
      };
      setIsLoading(true);
      changePassword(payload)
        .then((resss) => {
          setIsLoading(false);
          if (resss?.status == 200 || resss?.status == 201) {
            handleClose();
            handleSnackbarVal(true, "success", resss?.data?.message);
          } else {
            handleSnackbarVal(true, "error", resss?.data?.message);
          }
        })
        .catch((errr) => {
          setIsLoading(false);
          handleSnackbarVal(true, "error", errr?.message);
        });
    }
  };

  return (
    <div>
      <BootstrapDialog onClose={handleClose} open={open}>
        <DialogHeader title={"Change Password"} handleClose={handleClose} />
        <DialogContent dividers>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <InputLablessss label={"Old Password"} />
              <TextInput
                type={"password"}
                placeholder={"Old Password"}
                size="small"
                name="password"
                value={inputValue.password}
                onChange={handleChangeInput}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <InputLablessss label={"New Password"} />
              <TextInput
                type={"password"}
                placeholder={"New Password"}
                name="newPassword"
                value={inputValue.newPassword}
                onChange={handleChangeInput}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogFooter
          type={"edit"}
          handleUpdate={handleBtn}
          handleClose={handleClose}
        />
      </BootstrapDialog>
    </div>
  );
}
