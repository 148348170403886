export const requestType = {
  POST: "post",
  GET: "get",
  PUT: "put",
  DELETE: "delete",
};

export const endPoints = {
  //authentication
  createAdmin: "createAdmin",
  loginAdmin: "loginAdmin",
  forgotPasswordAdmin: "forgotPasswordAdmin",
  changePasswordAdmin: "changePasswordAdmin",
  updateAdminName: "updateAdminName",

  //home
  createHome: "createHome",
  getHome: "getHome",
  updateHome: "updateHome",

  //setting
  createSetting: "createSetting",
  getSetting: "getSetting",
  updateSetting: "updateSetting",

  //videoInfo
  createVideoInfo: "createVideoInfo",
  getVideoInfo: "getVideoInfo",
  updateVideoInfo: "updateVideoInfo",

  //loader
  createLoader: "createLoader",
  getLoader: "getLoader",
  updateLoader: "updateLoader",

  //cogratulation
  createCongratulation: "createCongratulation",
  getCongratulation: "getCongratulation",
  updateCongratulation: "updateCongratulation",

  //winner
  createWinner: "createWinner",
  getWinner: "getWinner",
  updateWinner: "updateWinner",

  //certificate
  createCertificate: "createCertificate",
  getCertificate: "getCertificate",
  updateCertificate: "updateCertificate",

  //packages
  getPackages: "getPackages",
  updatePackages: "updatePackages",
  getPlansHeading: "getPlansHeading",
  updatePlansHeading: "updatePlansHeading",

  //termsandprivacy
  getPrivacy: "getPrivacy",
  updatePrivacy: "updatePrivacy",

  //help faq
  createHelp: "createHelp",
  getAllHelp: "getAllHelp",
  updateHelp: "updateHelp",
  deletHelp: "deletHelp",

  //promo
  getPromo: "getPromo",
  updatePromo: "updatePromo",
};
