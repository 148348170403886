import { makeStyles } from "@mui/styles";
import { primaryColor, secondaryColor } from "../constants/Color";

const useStyles = makeStyles((theme) => ({
  loginContainer: {
    backgroundColor: "#fff",
    padding: "30px 20px",
    boxShadow: "0 0.2rem 1rem rgb(0 0 0 / 12%) !important",
    borderRadius: "12px",
    [theme.breakpoints.down(600)]: {
      width: "80%",
    },
  },

  root: {
    height: "100vh",
    justifyContent: "center",
    flexDirection: "column",
  },

  paper: {
    margin: theme.spacing(3, 3),
  },

  globalInput: {
    width: "100%",
    "&.MuiFormControl-root .MuiInputBase-root .MuiInputBase-input": {
      padding: "12px 0px !important",
      fontSize: "14px !important",
    },
    "&.MuiFormControl-root .MuiInputBase-root .MuiInputBase-input::placeholder":
      {
        fontSize: "14px !important",
      },
  },

  loginBtn: {
    "&.MuiButton-root": {
      backgroundColor: primaryColor,
      boxShadow: "none !important",
      width: "100%",
      padding: "6px",
      "&:hover": {
        backgroundColor: secondaryColor,
      },
    },
  },
  loginBtnText: {
    fontSize: "18px !important",
    textTransform: "none",
    fontWeight: "500 !important",
  },

  globalBtnStyle: {
    textTransform: "none !important",
    background: `${primaryColor} !important`,
    boxShadow: "none !important",
    "&:hover": {
      background: `${secondaryColor} !important`,
    },
  },

  globalInputLabel: {
    fontSize: "13px !important",
    margin: "5px 0",
    fontWeight: "500 !important",
  },

  globalDialogContentStyle: {
    background: "red",
  },

  globalSelectStyle: {
    width: "100%",
    "&.MuiFormControl-root .MuiInputBase-root .MuiSelect-select": {
      padding: "8px 12px !important",
      fontSize: "12px !important",
    },
    "&.MuiFormControl-root .MuiInputBase-root input": {
      fontSize: "12px !important",
    },
  },
}));

export default useStyles;
