import {
  AppBar,
  Avatar,
  Box,
  IconButton,
  InputAdornment,
  TextField,
  Toolbar,
  Typography,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import SearchIcon from "@mui/icons-material/Search";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import React, { useState } from "react";
import LogoutDialog from "./LogoutDialog";
import NotificationsNoneOutlinedIcon from "@mui/icons-material/NotificationsNoneOutlined";
import NavigationDrawerStyle from "./Style";
import { appImages } from "../assets";

const DrwerName = () => {
  return (
    <Box
      display={"flex"}
      sx={{ cursor: "pointer" }}
      flexDirection={"column"}
      alignItems={"center"}
      justifyContent={"center"}
      p={"10px 0 14.5px 10px"}
    >
      <Box width={140} height={40}>
        <img src={appImages.appLogo} height={"100%"} width={"100%"} />
      </Box>
    </Box>
  );
};

const CustomBar = ({ handleDrawerToggle }) => {
  const userData = JSON.parse(localStorage.getItem("user"));
  const classes = NavigationDrawerStyle();
  const [openLogoutDialog, setOpenLogoutDialog] = useState(false);
  const [openNotifDialog, setOpenNotiDialog] = useState(false);
  const drawerWidth = 240;

  const handleClickOpen = () => {
    setOpenLogoutDialog(true);
  };
  const handleCloseLogoutDialog = () => {
    setOpenLogoutDialog(false);
  };

  const handleOpenNotify = () => {
    setOpenNotiDialog(true);
  };
  const handleCloseNotifyDialog = () => {
    setOpenNotiDialog(false);
  };

  return (
    <AppBar
      position="fixed"
      className={classes.appBar}
      sx={{
        width: { sm: `calc(100% - ${drawerWidth}px) !important` },
        ml: { sm: `${drawerWidth}px !important` },
      }}
    >
      <Toolbar
        sx={{
          justifyContent: {
            xs: "space-between",
            md: "flex-end",
            paddingRight: "0px !important",
          },
        }}
      >
        <IconButton
          color="inherit"
          aria-label="open drawer"
          edge="start"
          onClick={handleDrawerToggle}
          sx={{ mr: 2, display: { sm: "none" } }}
        >
          <MenuIcon sx={{ color: "#000" }} />
        </IconButton>
        <Box display={"flex"} alignItems={"center"} gap={"10px"}>
          {/* <Box
            onClick={handleOpenNotify}
            sx={{ cursor: "pointer", marginRight: { xs: "0px", md: "15px" } }}
          >
            <NotificationsNoneOutlinedIcon sx={{ color: "#8d97ad" }} />
          </Box> */}
          <Typography
            variant={"body1"}
            fontSize={15}
            fontWeight={300}
            color={"#8d97ad"}
          >
            {userData?.name}
          </Typography>
          <ExpandMoreIcon
            sx={{ cursor: "pointer", color: "#8d97ad" }}
            onClick={handleClickOpen}
          />
          <Avatar
            sx={{
              height: "65px",
              width: "65px",
              backgroundColor: "#dde2eb",
              borderRadius: "0px",
            }}
            src={userData?.logoImage}
          />
        </Box>
        <LogoutDialog
          open={openLogoutDialog}
          handleClose={handleCloseLogoutDialog}
        />
        {/* <NotificationDialog
              open={openNotifDialog}
              handleClose={handleCloseNotifyDialog}
         />*/}
      </Toolbar>
    </AppBar>
  );
};

export { DrwerName, CustomBar };
