import React, { useEffect, useState } from "react";
import DrawerComponent from "../../../components/NavigationDrawer";
import { Box, Grid, Typography } from "@mui/material";
import InputLablessss from "../../../components/TextLabel";
import CustomButton from "../../../components/CustomButton";
import TextInput from "../../../components/TextInput";
import SnackbarAlert from "../../../components/SnackbarAlert";
import Loader from "../../../components/Loader";
import { fetchLoader, updateLoader } from "../../../services/loader";
import axios from "axios";
import { useRef } from "react";

function LoaderPage() {
  const imgRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [loaderData, setLoaderData] = useState(null);
  const [boxImage, setBoxImage] = useState(null);
  console.log(boxImage, "Sdadasdasdsdasdsdsa");
  const [inputValues, setInputValues] = useState({
    title: "",
    boxHeading: "",
    boxFollowers: "",
  });
  const [snackbarProps, setSnackbarProps] = useState({
    open: false,
    type: "",
    message: "",
  });

  const handleSnackbarVal = (open, type, message) => {
    setSnackbarProps({
      open,
      type,
      message,
    });
  };

  const handleCloseSnackbar = () => {
    handleSnackbarVal(false, "", "");
  };

  useEffect(() => {
    getLoaderData();
  }, []);

  const getLoaderData = () => {
    setIsLoading(true);
    fetchLoader()
      .then((res) => {
        setIsLoading(false);
        if (res?.data?.status == "ok") {
          let data = res?.data?.data;
          setInputValues({
            title: data?.title,
            boxHeading: data?.boxHeading,
            boxFollowers: data?.boxFollowers,
          });
          setBoxImage(data?.boxImage);
          setLoaderData(data);
          console.log(data, "datadatadata");
        } else {
          handleSnackbarVal(true, "error", res?.data?.message);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        handleSnackbarVal(true, "error", err?.message);
      });
  };

  const handleChangeInput = (e) => {
    const { value, name } = e.target;
    setInputValues({
      ...inputValues,
      [name]: value,
    });
  };

  const handleUpdate = () => {
    const { title, boxFollowers, boxHeading } = inputValues;
    if (!title) {
      handleSnackbarVal(true, "error", "Title is required");
    } else if (!boxHeading) {
      handleSnackbarVal(true, "error", "Box Heading is required");
    } else if (!boxFollowers) {
      handleSnackbarVal(true, "error", "Box Followers is required");
    } else if (boxImage === null) {
      handleSnackbarVal(true, "error", "Box Image is required");
    } else {
      setIsLoading(true);
      let payload = {
        title,
        boxFollowers,
        boxHeading,
        boxImage,
      };
      updateLoader(loaderData?._id, payload)
        .then((res) => {
          setIsLoading(false);
          if (res?.data?.status == "ok") {
            handleSnackbarVal(true, "success", res?.data?.message);
            getLoaderData();
          } else {
            handleSnackbarVal(true, "error", res?.data?.message);
          }
        })
        .catch((err) => {
          setIsLoading(false);
          handleSnackbarVal(true, "error", err?.message);
        });
    }
  };

  const handleImageChange = (event) => {
    setIsLoading(true);
    const formData = new FormData();
    formData.append("image", event.target.files[0]);
    axios({
      method: "post",
      url: "https://sirldigital.com/uploadService.php",
      data: formData,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((res) => {
        setBoxImage(res?.data?.url);
        setIsLoading(false);
        console.log(true, "success", res?.data?.message);
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(true, "error", error?.message);
      });
  };
  return (
    <DrawerComponent>
      <SnackbarAlert
        snackbarProps={snackbarProps}
        handleCloseSnackbar={handleCloseSnackbar}
      />
      <Loader isloading={isLoading} />
      <Typography variant={"h6"} fontSize={22} fontWeight={600}>
        Loader
      </Typography>
      <Grid container spacing={2} alignItems={"end"}>
        <Grid item xs={12} md={4}>
          <InputLablessss label={"Box Heading 1"} />
          <TextInput
            type={"text"}
            name={"title"}
            value={inputValues.title}
            onChange={handleChangeInput}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <InputLablessss label={"Box Heading 2"} />
          <TextInput
            type={"text"}
            name={"boxHeading"}
            value={inputValues.boxHeading}
            onChange={handleChangeInput}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <InputLablessss label={"Box Heading 3"} />
          <TextInput
            type={"text"}
            name={"boxFollowers"}
            value={inputValues.boxFollowers}
            onChange={handleChangeInput}
          />
        </Grid>
        <Grid item xs={12} md={2}>
          <Box width={"100%"} height={40}>
            <CustomButton
              btnLabel={"View Image"}
              handlePressBtn={() => window.open(boxImage, "_blank")}
            />
          </Box>
        </Grid>
        <Grid item xs={12} md={2}>
          <Box width={"100%"} height={40}>
            <CustomButton
              btnLabel={"Update Image"}
              handlePressBtn={() => imgRef.current.click()}
            />
          </Box>
          <input
            type="file"
            ref={imgRef}
            onChange={handleImageChange}
            style={{ display: "none" }}
          />
        </Grid>
        <Grid item xs={12} md={2}>
          <Box width={"100%"} height={40}>
            <CustomButton btnLabel={"Update"} handlePressBtn={handleUpdate} />
          </Box>
        </Grid>
      </Grid>
    </DrawerComponent>
  );
}

export default LoaderPage;
